var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gallery_page"},[_c('div',{staticClass:"gallery_head"},[_c('div',{style:({ width: _vm.$mq == 'mobile' ? '70%' : '40%' })},[_c('h5',[_vm._v("Gallery")]),_c('h1',{staticClass:"mb24"},[_vm._v(_vm._s(_vm.info.title))]),_c('p',[_vm._v(" "+_vm._s(_vm.info.paragraph)+" ")]),(_vm.info.actionLink)?_c('button',{staticClass:"mt24 black",on:{"click":function($event){return _vm.$bus.redirect(_vm.info.actionLink, false, true)}}},[_vm._v(_vm._s(_vm.info.actionText))]):_vm._e()]),(_vm.$mq != 'mobile')?_c('div',{staticClass:"gallery_right",staticStyle:{"width":"60%"}},_vm._l((_vm.info.displayPhotos.slice(1, 4)),function(p){return _c('div',{key:p.id,staticClass:"gallery_display",style:({ background: 'url(' + p.url + ') center / contain no-repeat' })})}),0):_vm._e()]),(_vm.info.displayPhotos.length > 0)?_c('div',{staticClass:"wfill",staticStyle:{"height":"50vh"},style:({
      background:
        'url(' + _vm.info.displayPhotos[0].url + ') center / cover no-repeat'
    })}):_vm._e(),_vm._m(0),_c('div',[(_vm.masterEvents.length > 0)?_c('div',{staticClass:"me_chip_parent",staticStyle:{"border-bottom":"1px solid #eee"}},[_c('div',{staticClass:"flex wrap",staticStyle:{"flex-wrap":"true"}},_vm._l((_vm.masterEvents),function(me){return _c('div',{key:me.id,staticClass:"me_chip",class:{ active: _vm.activeMasterEventId == me.id },on:{"click":function($event){_vm.activeMasterEventId != me.id
              ? (_vm.activeMasterEventId = me.id)
              : (_vm.activeMasterEventId = '')}}},[_vm._v(" "+_vm._s(me.name)+" "),(_vm.galleryCounts[me.id])?_c('span',[_vm._v("("+_vm._s(_vm.galleryCounts[me.id])+")")]):_vm._e()])}),0)]):_vm._e()]),_c('div',{staticClass:"nowrap mt12 ml48",staticStyle:{"border":"1px solid #ccc","width":"200px","padding":"0 12px"}},[_c('i',{staticClass:"ml8 search icon"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"search_input",attrs:{"placeholder":"Search","type":"text"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}})]),(!_vm.loading)?_c('div',{staticClass:"gallery_grid"},_vm._l((_vm.searchedGalleries),function(g){return _c('div',{key:g.id,staticClass:"pointer gallery",style:({
        background: 'url(' + g.photos[0] + ') center / cover no-repeat'
      }),on:{"click":function($event){return _vm.showGalleryModal(g.id)}}},[_c('div',{staticClass:"mtauto p16",staticStyle:{"background":"white","border-top":"1px solid #eee"}},[_c('h6',[_vm._v(_vm._s(g.Title))]),_c('p',{staticClass:"mt4"},[_vm._v(_vm._s(g.photos.length - 3)+" images")])]),(_vm.activeGallery == g.id)?_c('vue-gallery-slideshow',{attrs:{"images":g.photos.slice(0, g.photos.length - 3),"index":0},on:{"click":ev => {
            ev.stopPropagation();
          }}}):_vm._e()],1)}),0):_c('div',{staticClass:"my-8 flex wfill"},[_c('h6',{staticClass:"wfill mx-auto italic",staticStyle:{"text-align":"center"}},[_vm._v(" Loading... ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt40"},[_c('h2',{staticClass:"center"},[_vm._v("View Gallery")]),_c('p',{staticClass:"center mt12"},[_vm._v(" Select an event below to browse through the image catalogue ")])])
}]

export { render, staticRenderFns }