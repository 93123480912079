<template>
  <div class="gallery_page">
    <div class="gallery_head">
      <div :style="{ width: $mq == 'mobile' ? '70%' : '40%' }">
        <h5>Gallery</h5>
        <h1 class="mb24">{{ info.title }}</h1>
        <p>
          {{ info.paragraph }}
        </p>
        <button 
          v-if="info.actionLink"
          @click="$bus.redirect(info.actionLink, false, true)" 
          class="mt24 black">{{ info.actionText }}</button>
      </div>
      <div v-if="$mq != 'mobile'" style="width: 60%" class="gallery_right">
        <div
          v-for="p in info.displayPhotos.slice(1, 4)"
          :key="p.id"
          class="gallery_display"
          :style="{ background: 'url(' + p.url + ') center / contain no-repeat' }"
        ></div>
      </div>
    </div>
    <div
      v-if="info.displayPhotos.length > 0"
      class="wfill"
      style="height: 50vh;"
      :style="{
        background:
          'url(' + info.displayPhotos[0].url + ') center / cover no-repeat'
      }"
    ></div>
    <div class="mt40">
      <h2 class="center">View Gallery</h2>
      <p class="center mt12">
        Select an event below to browse through the image catalogue
      </p>
    </div>

    <div>
      <div
        v-if="masterEvents.length > 0"
        class="me_chip_parent"
        style="border-bottom: 1px solid #eee;"
      >
        <div class="flex wrap" style="flex-wrap: true">
          <div
            v-for="me in masterEvents"
            :key="me.id"
            :class="{ active: activeMasterEventId == me.id }"
            @click="
              activeMasterEventId != me.id
                ? (activeMasterEventId = me.id)
                : (activeMasterEventId = '')
            "
            class="me_chip"
          >
            {{ me.name }}
            <span v-if="galleryCounts[me.id]">({{ galleryCounts[me.id] }})</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="nowrap mt12 ml48"
      style="border: 1px solid #ccc; width: 200px; padding: 0 12px;"
    >
      <i class="ml8 search icon"></i>
      <input
        placeholder="Search"
        type="text"
        class="search_input"
        v-model="search"
      />
    </div>
    <!-- <div
      v-for="g in galleries"
      :key="g.id"
      class="ui fullscreen modal"
      :id="g.id"
    >
      <div class="header">{{ g.Title }}</div>
      <gallery-widget :media="g.photos" />
      <div class="actions">
        <div @click="closeModal(g.id)" class="ui cancel button">Close</div>
      </div>
    </div> -->
    <div class="gallery_grid" v-if="!loading">
      <div
        v-for="g in searchedGalleries"
        :key="g.id"
        class="pointer gallery"
        @click="showGalleryModal(g.id)"
        :style="{
          background: 'url(' + g.photos[0] + ') center / cover no-repeat'
        }"
      >
        <div
          class="mtauto p16"
          style="background: white; border-top: 1px solid #eee;"
        >
          <h6>{{ g.Title }}</h6>
          <p class="mt4">{{ g.photos.length - 3 }} images</p>
        </div>
        <vue-gallery-slideshow
          v-if="activeGallery == g.id"
          @click="
            ev => {
              ev.stopPropagation();
            }
          "
          :images="g.photos.slice(0, g.photos.length - 3)"
          :index="0"
        ></vue-gallery-slideshow>
      </div>
    </div>
    <div v-else class="my-8 flex wfill">
      <h6 style="text-align: center" class="wfill mx-auto italic">
        Loading...
      </h6>
    </div>
  </div>
</template>

<style>
.gallery_display {
  width: 40vh;
  height: 40vh;
  border-radius: 4px;
  position: absolute;
}
.gallery_display:first-child {
  right: 10vw;
  top: -4vw;
}
.gallery_display:nth-child(2) {
  right: 25vw;
  top: 10vh;
}
.gallery_display:last-child {
  right: 30vw;
  top: 15vh;
}
.gallery_head {
  padding-top: 80px;
  margin-left: 6%;
  width: 100%;
  height: 70vh;
  display: flex;
}
.gallery_right {
  width: 50%;
  position: relative;
}
.gallery_grid {
  display: flex;
  padding: 20px 40px;
  border-radius: 12px;
  flex-wrap: wrap;
}
.gallery {
  width: 220px;
  height: 220px;
  background: #eee;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
@media (max-width: 600px) {
  .gallery {
    width: 100%;
  }
}
.gallery h6 {
  font-size: 14px !important;
}
.gallery p {
  font-size: 12px !important;
}
.gallery_page .ui.modal {
  position: fixed;
  top: 60px;
}
</style>

<script>
import qs from "qs";
// import galleryWidget from "../components/gallery-widget";
import { galleryKey } from '../utils/constants'
import VueGallerySlideshow from "vue-gallery-slideshow";
export default {
  name: "gallery",
  components: {
    VueGallerySlideshow
  },
  data() {
    return {
      loading: false,
      search: "",
      activeGallery: null,
      galleries: [],
      info: { displayPhotos: [], indexedPhotoUrls: [] },
      masterEvents: [],
      activeMasterEventId: '',
      galleryKey: galleryKey
    };
  },
  mounted() {
    this.$req
      .get("gallery")
      .then(r => {
        this.info = r.data;
      })
      .catch(e => console.log(e));
    this.$req
      .get('master-events')
      .then(r => {
        this.masterEvents = r.data
      }).catch(e => console.log(e))
    // this.getGalleries();
  },
  computed: {
    masterEventsKey: function () {
      return this.masterEvents.reduce((acc, obj) => {
        acc[obj.name] = obj.id
        return acc
      }, {})
    },
    searchedGalleries: function() {
      let galleries = [...this.combinedGalleries]
      if (this.search) {
        galleries = this.galleries?.filter(g =>
          g.Title?.toLowerCase()?.includes(this.search?.toLowerCase())
        );
      }
      if (this.activeMasterEventId) {
        galleries = galleries?.filter(g => 
          g?.master_event == this.activeMasterEventId
        )
      }
      return galleries;
    },
    galleryCounts: function () {
      return this.combinedGalleries.reduce((acc, g) => {
        if (g.master_event) {
          acc[g.master_event] = acc[g.master_event] || 0
          acc[g.master_event] += 1
        }
        return acc
      }, {})
    },
    externalGalleries: function () {
      try {
        const results = Object.entries(galleryKey).map(([name, links]) => {
          let master_event = ''
          for (let [nm, id] of Object.entries(this.masterEventsKey)) {
            if (name.includes(nm)) {
              master_event = id
              break
            }
          }
          return {
            id: name,
            Title: name,
            master_event,
            photos: [...links.map(l => 'https://ethosstrapibucket.s3.ap-south-1.amazonaws.com/images/' + l), "", "", ""]
          }
        })
        return results
      } catch (e) {
        return []
      }
    },
    combinedGalleries: function () {
      // const ext_galleries = this.externalGalleries.map(g => {
      //   g.photos = [...g.photos, "", "", ""]
      //   return g
      // })
      return [...(this.info.indexedPhotoUrls || []),...(this.externalGalleries || [])];
    }
  },
  methods: {
    closeModal: function(id) {
      /* eslint-disable */
      $(`#${id}`).hide();
    },
    showGalleryModal: function(id) {
      /* eslint-disable */
      // $(`#${id}`).show();
      if (this.activeGallery == id) this.activeGallery = null;
      else {
        this.activeGallery = id;
      }
    },
    getGalleries: function() {
      let filter = {
        _where: {
          googlePhotosUrl_ne: ""
        },
        _limit: -1
      };
      let query = qs.stringify(filter);
      this.loading = true;
      this.$req
        .get(`events?${query}`)
        .then(r => {
          // this.galleries = r.data
          this.$req
            .post("getPhotosFromAlbums", {
              albums: r.data
            })
            .then(res => {
              const ext_galleries = this.externalGalleries.map(g => {
                g.photos = [...g.photos, "", "", ""]
                return g
              })
              this.galleries = [...this.externalGalleries, ...res.data];
              this.loading = false;
            })
            .catch(e => console.log(e));
        })
        .catch(e => console.log(e));
    }
  }
};
</script>